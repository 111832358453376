import React from 'react';
import {Link, graphql, navigate} from 'gatsby';
import {Helmet} from 'react-helmet';
import Layout from '../../components/Layout';
import PreviewCompatibleImage from '../../components/PreviewCompatibleImage';
import { AddToCart } from '../../components/AddToCart';
import { format } from '../../utils/format';
import { sortByMinVariantPriceAscending , ProductLink } from '../../utils/product';


const ProductsListTemplate = ({products}) => (
    <div className="section--white">
        <div className="container">
            <section className="section">
                <div className="container pt-5">
                    <div className="content">
                        <div className="columns is-multiline is-justify-content-space-between sitteroo is-mobile">
                            {products && products.map(({node}) => (
                                <div
                                    className="
                                    column
                                    card
                                    m-3
                                    p-3
                                    is-3-desktop
                                    is-narrow-desktop
                                    is-5-tablet
                                    product
                                "
                                    key={node.shopifyId}
                                >
                                    <div className="card-image">
                                        <figure className="image">
                                            <PreviewCompatibleImage
                                                imageInfo={{
                                                    childImageSharp: node.media[0].image.localFile.childImageSharp,
                                                    alt: `Image for ${node.title}`,
                                                    image: node.media[0].image
                                                }}
                                            />
                                        </figure>
                                    </div>
                                    <div className="card-content">
                                        <div className="media has-text-centered">
                                            <div className="media-content">
                                                <Link
                                                    className="sitteroo-blog-list-heading"
                                                    to={ProductLink(node)}
                                                >
                                                    {node.title}
                                                </Link>
                                            </div>
                                        </div>
                                        <p className="has-text-centered">
                                            {node.title.match(/Birth Certificate|Magic Star Map/gm) ? null :
                                                format(
                                                    node.priceRangeV2.minVariantPrice.currencyCode,
                                                    node.priceRangeV2.minVariantPrice.amount,
                                                )
                                            }
                                        </p>
                                    </div>
                                    <footer className="card-footer pb-5">
                                        <div className="card-footer-item is-justify-content-center">
                                            {node.title.match(/Birth Certificate|Magic Star Map/gm) ?
                                                (
                                                    <button
                                                        type="submit"
                                                        className="sitteroo-button bg-grey"
                                                        onClick={() => {
                                                            navigate(ProductLink(node))
                                                        }}
                                                    >
                                                        Customise
                                                    </button>
                                                )
                                                :
                                                (
                                                    <AddToCart
                                                        variantId={node.variants[0].storefrontId}
                                                        variantName={node.variants[0].displayName}
                                                        variantPrice={node.variants[0].price}
                                                        quantity={1}
                                                        available
                                                        customAttr={[]}
                                                    />
                                                )
                                            }
                                        </div>
                                    </footer>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
);

const ProductsList = ({ data }) => {
    const {
        allSitterooShopifyProduct: { edges },
    } = data;

    return (
        <Layout>
            <Helmet
                titleTemplate="%s"
                title="Sitteroo products"
            >
                <meta
                    name="description"
                    content="List of products for modern parents."
                />
            </Helmet>

            <ProductsListTemplate
                products={edges.sort(sortByMinVariantPriceAscending)}
            />
        </Layout>
    );
};

export default ProductsList;

export const query = graphql`
  {
    allSitterooShopifyProduct(
      sort: {order: ASC, fields: priceRangeV2___maxVariantPrice___amount}
    ) {
    edges {
      node {
        handle
        title
        shopifyId
        description
        priceRangeV2 {
          maxVariantPrice {
            amount,
            currencyCode
          }
          minVariantPrice {
            amount,
            currencyCode
          }
        }
        status
        media {
          ... on SitterooShopifyMediaImage {
            id
            image {
              src
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 447, layout: CONSTRAINED, placeholder: BLURRED)
                }
              }
            }
          }
        }
        storefrontId
        variants {
          id
          displayName
          storefrontId
          price
        }
      }
    }
  }
}`;
