import React, { useContext } from 'react';
import { StoreContext } from '../context/store-context';
import window, { ga4 } from '../utils/window';

const analytics = ga4();
export function AddToCart({
    variantId,
    variantName,
    variantPrice,
    quantity,
    available,
    customAttr,
    disabled,
    ...props
}) {
    const { addVariantToCart, loading } = useContext(StoreContext);

    function addToCart(e) {
        e.preventDefault();
        addVariantToCart(variantId, quantity, customAttr);
        if (typeof window === 'undefined') {
            return;
        }
        if (window.fbq != null) {
            window.fbq('track', 'AddToCart', {
                content_name: variantName,
                content_ids: [variantId],
                content_type: 'product',
                value: variantPrice,
            });
        }

        if (analytics !== null) {
            analytics('event', 'add_to_cart', {
                value: variantPrice,
                currency: 'AUD',
                items: [
                    {
                        item_name: variantName,
                        item_id: variantId,
                        price: variantPrice,
                        quantity: 1,
                    }
                ],
            });
        }
    }

    return (
        <button
            type="submit"
            className="sitteroo-button bg-grey"
            onClick={addToCart}
            disabled={!available || loading || disabled}
            {...props}
        >
            {available ? 'Add to Cart' : 'Unavailable'}
        </button>
    );
}
